<template>
    <div class="login-container login text-center animated flipInX">
      <div class="container-content">
          <transition name="slide-fade" appear>
            <form @submit.prevent="handleSubmit">
              <img src="@/assets/empresa.svg" alt="User" class="img-login">
              <h5 class="text-whitesmoke">PORTAL DE PROVEEDORES</h5>
              <p class="text-whitesmoke">Restaurar contraseña</p>
              <div class="form-group" :class="{error: validation.hasError('password')}" v-if="istokenValid">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'lock', }"/></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Nueva contraseña" v-model="password">
                  <span class="icon" :title="validation.firstError('password')" v-if="validation.hasError('password')">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                  </span>
                </div>
              </div>
              <label class="p text-whitesmoke" v-if="!istokenValid">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                El Token de recuperación NO es Válido, reintenta enviar el correo de recuperación.
              </label>
              <button type="submit" class="form-button btn-block mb-2" v-if="istokenValid">
                <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>ACTUALIZAR CONTRASEÑA
              </button>
              <router-link class="text-gray" :to="'/auth/login'">
                <small><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }"/> VOLVER A INICIO DE SESIÓN</small>
              </router-link>
            </form>
          </transition>
      </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'login',
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
  },
  data() {
    return {
      password: '',
      istokenValid: false,
    };
  },
  validators: {
    password(value) { return Validator.value(value).required('Campo requerido').minLength(6, 'Longitud mínima de 6 caracteres'); },
  },
  mounted() {
    this.verifyToken(this.$route.params.token);
  },
  methods: {
    verifyToken(token) {
      apiClient.post('/verify-token', { token })
        .then((res) => {
          this.istokenValid = res.data.value;
        }).catch(() => {
        });
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.$store.dispatch('user/RESET_PASSWORD', { payload: { token: this.$route.params.token, password: this.password } });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
